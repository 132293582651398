import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import bg from './Pic/bg.jpg'
import PublicUrl from './config/config.js';

const VerifyEmailPage = ({ match }) => {
  const [message, setMessage] = useState('');
  const { token } = useParams();
  console.log(token)

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(`${PublicUrl}/verify-email`, { token });
        setMessage(response.data.message);
      } catch (error) {
        setMessage('Failed to verify email. Please try again.');
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div class="bgregis" style={{backgroundImage:`url(${bg})`}}>
      {/* <Container style={{ marginBottom: "20px" }}> */}
  
        
            <div className="bgblur" style={{height:"600px"}}>
       
              <div class="row flex-nowrap justify-content-between align-items-center">
                <div
                  class="col-12 text-center"
                  style={{
                   
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <p style={{color:'white',fontWeight:'bold',fontSize:'45px'}}>Email Verification</p>
                  <div className='emailbox'><p className='emailboxp' >{message}</p></div>
                </div>
              </div>
        <div className=" buttonlog" >
          <Button href="/Login" className='button2' variant="secondary" size="sm" style={{fontSize:'20px', fontFamily: 'Roboto Slab',fontWeight:'bold'}}>
        Login
          </Button>
        </div></div>
      {/* </Container> */}
    </div>
  );
};

export default VerifyEmailPage;
